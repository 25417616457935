<!--学员-转班 -->
<template>
    <div class="ShiftTransfer">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">转班</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="选择目标班级" prop="target">
                <el-select v-model="form.target" filterable @change="targetChange" placeholder="请选择" :clearable="true">
                    <el-option
                      v-for="item in form.targetOpt"
                      :key="item.id"
                      :label="item.class_name"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="安排单号">
                <el-input  placeholder="" v-model="form.classNumber" disabled></el-input>
            </el-form-item>
            <el-form-item label="班级编号">
                <el-input  placeholder="" v-model="form.classCompile" disabled></el-input>
            </el-form-item>
            <el-form-item label="学科">
                <el-input  placeholder="" v-model="form.subject" disabled></el-input>
            </el-form-item>
            <el-form-item label="授课老师">
                <el-input  placeholder="" v-model="form.teacher" disabled></el-input>
            </el-form-item>
            <el-form-item label="计划招生人数">
                <el-input  placeholder="" v-model="form.planPeople" disabled></el-input>
            </el-form-item>
            <el-form-item label="实际人数">
                <el-input  placeholder="" v-model="form.actualPeople" disabled></el-input>
            </el-form-item>
            <el-form-item label="标准学杂费">
                <el-input  placeholder="" v-model="form.standard" disabled></el-input>
            </el-form-item>
            <el-form-item label="其中学费">
                <el-input  placeholder="" v-model="form.tuition" disabled></el-input>
            </el-form-item>
            <el-form-item label="杂费">
                <el-input  placeholder="" v-model="form.incidental" disabled></el-input>
            </el-form-item>
            <el-form-item label="开班日期">
                <el-input  placeholder="" v-model="form.openDate" disabled></el-input>
            </el-form-item>
            <el-form-item label="上课地点">
                <el-input  placeholder="" v-model="form.locale" disabled></el-input>
            </el-form-item>
            <el-form-item label="课时安排">
                <el-input class="text-ipt" placeholder="" v-model="form.teachingHours" disabled></el-input>
            </el-form-item>
            <el-form-item label="结业日期">
                <el-input  placeholder="" v-model="form.graduationDate" disabled></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="carryOut('form')">
                执行转班
            </el-button>
            <el-button class="btn" @click="halfway('form')">中途转班</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
               form:{
                 target:"",//目标班级
                 targetOpt:[],//班级列表选项
                 classNumber:"",//班级单号
                 classCompile:"",//班级编号
                 subject:"",//学科
                 teacher:"",//授课老师
                 planPeople:"",//计划招生人数
                 actualPeople:"",//实际人数
                 standard:"",//标准学杂费
                 tuition:"",//其中学费
                 incidental:"",//杂费
                 openDate:"",//开班日期
                 locale:"",//上课地点
                 teachingHours:"",//课时安排
                 graduationDate:"",//结业日期
               },
               rules:{
                   target:[
                       { required: true, message: '请选择目标班级', trigger: 'change' },
                   ]
               },
               bool:false,
               flag:false
            }
        },
        created() {
            // 目标班级数据
            this.$request({
                url:'/api/class_/list',
                method:'POST',
                data:{
                    limit:100,
                    page:1
                }
            }).then(res=>{
                if(res.code==1){
                    this.form.targetOpt=res.data.list
                }
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            targetChange(e){//选择之后渲染其余数据
                let result = this.form.targetOpt.find(item => item.id == e)
                console.log(result)
                let form = this.form
                form.classNumber=result.arrange_number//安排单号
                form.classCompile=result.number//班级编号
                form.subject=result.course//学科
                form.teacher=result.resources//授课老师
                form.planPeople=result.planned_size//计划招生人数
                form.actualPeople=result.actual_size//实际人数
                form.standard=result.tuition_incidentals//标准学杂费
                form.tuition=result.tuition//其中学费
                form.incidental=result.incidentals//杂费
                form.openDate=result.start_time//开班日期
                form.locale=result.classroom//上课地点
                form.teachingHours=result.class_arrangement//课时安排
                form.graduationDate=result.end_time//结业日期
            },
            
            carryOut(form){//执行转班
                this.$refs[form].validate((valid) => {
                      if (valid) {
                          if(this.bool){
                              return
                          }
                          this.bool=true
                        // alert('submit!');
                        this.$confirm('执行转班，是否继续?', '提示', {
                            confirmButtonText: '确定',
                            acncelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            
                        }).catch(()=>{
                            
                        })
                      } else {
                        console.log('error submit!!');
                        return false;
                      }
                    });
               
            },
            halfway(form){//中途转班
                this.$refs[form].validate((valid) => {
                      if (valid) {
                        // alert('submit!');
                        if(this.bool){
                            return
                        }
                        this.bool=true
                        this.$confirm('中途转班，是否继续?', '提示', {
                            confirmButtonText: '确定',
                            acncelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            
                        }).catch(()=>{
                            
                        })
                      } else {
                        console.log('error submit!!');
                        return false;
                      }
                    });
                
            },
        }
    }
</script>

<style scoped="scoped">
    .ShiftTransfer{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    .el-select{
        width: 300px;
        height: 37px !important;
    }
    ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
        color: #606266;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    .btn{
        background-color:#FF7300;
        color: #FFFFFF;
    }

</style>
